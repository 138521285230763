table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

sidebarTable {
  border: 1px solid black;
  border-collapse: collapse;
}

/*   tr:nth-of-type(odd)> */

sidebarTableTr {
  /*    --bs-table-accent-bg: var(--bs-table-striped-bg); */
  color: #212529;
}
