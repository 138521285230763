.text-display {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.btn-toggle {
  margin-top: 4px;
}

.text-display.collapsed {
  animation: mask-collapsing 0.5s;
  /*  mask-image: linear-gradient(black 50%, transparent); */
}

.text-display.expanded {
  animation: mask-expanding 0.5s;
  /*  mask-image: linear-gradient(black 100%, transparent); */
}

@keyframes mask-collapsing {
  from {
    mask-image: linear-gradient(black 100%, transparent);
  }
  to {
    mask-image: linear-gradient(black 50%, transparent);
  }
}

@keyframes mask-expanding {
  from {
    mask-image: linear-gradient(black 50%, transparent);
  }
  to {
    mask-image: linear-gradient(black 100%, transparent);
  }
}
