.pageTopImage {
  width: 100%;
  height: auto;
  vertical-align: middle;
  /* margin-bottom: 12px; */
  /*  margin-top: 50px;  */
  /*  border-radius: 5%; */
  overflow: hidden;
  border-width: 3;
  border-color: red;
}

.pageTopBottomSpace {
  margin-bottom: 50px;
}

.pageList {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 9px;
  margin-top: 150px;
  margin-bottom: 5px;
}

.tableImage {
  width: 190px;
}
